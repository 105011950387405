import { Injectable } from '@angular/core';
import { RestService } from '../../services/rest.service';
import { FlowStatus } from '../../shared/models/flow-status';
import { Application } from '../../shared/models/application';
import { Observable, Subject } from 'rxjs';
import { IClientItemDTO, IClientListDTO } from '../../shared/models/common.dto';
import { ProducerType, ApplicationProgress } from '../../shared/enums/general';
import { Producer, ProducerRelationship } from '../../shared/models/producer';
import { ApplicationDocument } from '../../shared/models/document';
import { AppointmentState } from '../../shared/models/appointment-state';
import { Contact } from '../../shared/models/contact';
import { AuditLogStatus } from '../../shared/enums/audit-log';

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends RestService {

    private appId: Subject<number> = new Subject<number>();
    get appId$() {
        return this.appId.asObservable();
    }
    updateAppId(val: number) {
        if (isNaN(val)) {
            this.clear();
        }
        else {
            this.appId.next(val);
        }
    }
    
    statesList: string[] = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

    application: Application;

    documents: ApplicationDocument[];

    producer: Producer;
    producerHierarchy: Producer;
    producerLoaded: Subject<boolean> = new Subject<boolean>();

    steps: FlowStatus[];
    activeStep: FlowStatus;
    nextStepBtnText: string;
    activeStepIndex: number;
    redirectRoute = new Subject<string>();
    initRoute: string;

    setActiveStep(step: FlowStatus) {
        let oldStep = this.activeStep;
        if (oldStep) {
            oldStep.active = false;
        }
        step.active = true;
        this.activeStep = step;
        this.nextStepBtnText = step.nextStepBtnText;
        this.activeStepIndex = this.steps.indexOf(step);
    };

    clear(): void {
        this.updateAppId(null);
    }

    getApplication(id): Observable<IClientItemDTO<Application>> {
        let relativeUrl = 'Application/' + id;
        return this.get<IClientItemDTO<Application>>(relativeUrl);
    }

    getBizUnitVerbiage(bizUnitId : number, type: string): Observable<IClientItemDTO<string>> {

        let relativeUrl = `Application/BizUnit/${bizUnitId}/Verbiage/${type}`;
        return this.get<IClientItemDTO<string>>(relativeUrl);
    }

    setApplication(app: Application) {
        this.application = app;
        
        if (app) {
            this.setupSteps(app);
            this.callGetProducer();

            if (app.progressStatus == null || app.progressStatus == ApplicationProgress[ApplicationProgress.None]) {
                this.updateProgress(ApplicationProgress[ApplicationProgress.Opened]).subscribe(resData => { });
            }
        }
    }

    callGetProducer(): void {
        this.producerLoaded.next(false);
        this.getProducer(this.application.producer.id).subscribe(resData => {
            if (resData && resData.status == 0 && resData.item) {
                this.producer = new Producer(resData.item);
                this.producerLoaded.next(true);
            }
        });
    }

    getProducer(id: number): Observable<IClientItemDTO<Producer>> {
        let relativeUrl = 'Producer/All/' + id;
        return this.get<IClientItemDTO<Producer>>(relativeUrl);
    }

    completeSaveAndNext() {
        let step = this.steps[this.activeStepIndex];
        if (!step.completed) {
            step.completed = true;
            step.current = false;

            this.updateProgress().subscribe(res => {
                if (res) {
                    this.application.progressStatus = this.activeStep.route;
                    this.advanceStep();
                }
                else {
                    console.log('status failed to update!');
                }
            });
        }
        else {
            this.advanceStep();
        }
    }

    advanceStep() {
        if (this.steps.length > this.activeStepIndex + 1) {
            let step = this.steps[this.activeStepIndex + 1];
            if (!step.completed) {
                step.current = true;
            }
            this.redirectRoute.next(step.route);
        }
    }

    updateProgress(status?: string): Observable<IClientItemDTO<boolean>> {
        if (!status) {
            status = this.activeStep.route;
        }
        let relativeUrl = 'Application/' + this.application.id + '/ProgressStatus';
        return this.patch<IClientItemDTO<boolean>>(relativeUrl, status);
    }

    updateProducer(): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'Producer/Update';
        let prod = new Producer(this.producer);
        prod.contact = null;
        prod.licenses = null;

        return this.post<IClientItemDTO<boolean>>(relativeUrl, prod);
    }

    updatePartialContact(contact: Contact): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'Producer/' + this.producer.id + '/PartialContact';
        return this.post<IClientItemDTO<boolean>>(relativeUrl, contact);
    }

    updateAppointmentStates(appts: AppointmentState[]): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = "Application/" + this.application.id + "/AppointedState";

        return this.post<IClientItemDTO<boolean>>(relativeUrl, appts);
    }

    getProducerHierarchy(): Observable<IClientItemDTO<Producer>> {
        let relativeUrl = 'Producer/Hierarchy/' + this.producer.id;
        return this.get<IClientItemDTO<Producer>>(relativeUrl);
    }

    removeProducerRelationship(agent: Producer): Observable<IClientItemDTO<boolean>> {
        let prod = this.application.producer;
        let relativeUrl = 'Producer/RemoveProducerRelation/';
        let input: ProducerRelationship = new ProducerRelationship();
        input.parentProducerId = prod.id;
        input.childProducerId = agent.id;

        return this.post<IClientItemDTO<boolean>>(relativeUrl, input);
    }

    addProducerRelationship(agentId: number): Observable<IClientItemDTO<boolean>> {
        let prod = this.application.producer;
        let relativeUrl = 'Producer/AddProducerRelation/';
        let input: ProducerRelationship = new ProducerRelationship();
        input.parentProducerId = prod.id;
        input.childProducerId = agentId;
        input.isPrimaryAgent = false;

        return this.post<IClientItemDTO<boolean>>(relativeUrl, input);
    }

    setPrimaryAgent(agentId: number): Observable<IClientItemDTO<boolean>> {
        let relativeUrl = 'Producer/' + this.application.producer.id + '/PrimaryAgent/' + agentId;

        return this.post<IClientItemDTO<boolean>>(relativeUrl, null);
    }

    startDocuSign(): Observable<IClientItemDTO<string>> {
        let relativeUrl = 'DocuSign/StartDocuSign/' + this.application.id;

        return this.post<IClientItemDTO<string>>(relativeUrl, null);
    }

    validateDocuSign(): Observable<IClientItemDTO<string>> {
        let relativeUrl = "DocuSign/EnvelopeStatus/" + this.application.id;

        return this.get<IClientItemDTO<string>>(relativeUrl);
    }

    completeApplication() {
        let finalStep = new FlowStatus("Application Complete", "Completed", true);

        this.updateProgress(finalStep.route).subscribe(res => {
            if (res && res.status == 0 && res.item) {
                this.steps = [];
                this.steps.push(finalStep);
                   
                this.redirectRoute.next(finalStep.route);
            }
        });
    }

    getStep(stepRoute: string) {
        if (this.steps) {
            return this.steps.find(s => s.route === stepRoute);
        }
    }

    setupSteps(app: Application) {
        let redirect: string;
        let activeStep: FlowStatus;

        if (this.application.status == AuditLogStatus.AppDecline) {
            activeStep = new FlowStatus("Application Declined", "Declined", true);
            redirect = activeStep.route;
            this.steps = [];
            this.steps.push(activeStep);
        }
        else if (this.application.status == AuditLogStatus.AppApprove) {
            activeStep = new FlowStatus("Application Approved", "Approved", true);
            redirect = activeStep.route;
            this.steps = [];
            this.steps.push(activeStep);
        }
        else if (this.application.progressStatus == "Completed") {
            activeStep = new FlowStatus("Application Complete", "Completed", true);
            redirect = activeStep.route;
            this.steps = [];
            this.steps.push(activeStep);
        }
        else {
            let steps: FlowStatus[] = [];
            if (app.producer.type == ProducerType[ProducerType.Agent]) {
                steps.push(new FlowStatus("Producer Information", ApplicationProgress[ApplicationProgress.ProducerInformation], true));
                if (this.checkIfLicenseStepNeeded()) {
                    steps.push(new FlowStatus("State License(s)", ApplicationProgress[ApplicationProgress.StateLicense]));
                }
                steps.push(new FlowStatus("Documents", ApplicationProgress[ApplicationProgress.AddDocuments]));
                steps.push(new FlowStatus("E-Signature", ApplicationProgress[ApplicationProgress.ESignature]));
            }
            else if (app.producer.type == ProducerType[ProducerType.Agency]) {
                steps.push(new FlowStatus("Producer Information", ApplicationProgress[ApplicationProgress.ProducerInformation], true));
                if (this.checkIfLicenseStepNeeded()) {
                    steps.push(new FlowStatus("State License(s)", ApplicationProgress[ApplicationProgress.StateLicense]));
                }
                if (this.application.allowToAddAgent) {
                    steps.push(new FlowStatus("Agent(s)", ApplicationProgress[ApplicationProgress.AddAgents]));
                }
                steps.push(new FlowStatus("Documents", ApplicationProgress[ApplicationProgress.AddDocuments]));
                steps.push(new FlowStatus("E-Signature", ApplicationProgress[ApplicationProgress.ESignature]));
            }

            this.steps = steps;
            activeStep = steps[0];

            let appStatus = this.application.progressStatus;

            if (appStatus && appStatus != "None" && appStatus != "Opened" && this.application.id) {
                let stepCount = this.steps.length;
                let i = 0;
                for (i; i < stepCount; i++) {
                    activeStep = this.steps[i];
                    activeStep.completed = true;
                    if (activeStep.route == appStatus) {
                        break;
                    }
                    activeStep.active = false;
                }

                if (i < this.steps.length - 1) {
                    i++;
                }
                activeStep = this.steps[i];
            }

            redirect = activeStep.route;
            activeStep.current = true;

            if (this.initRoute) {
                let tryStep = this.getStep(this.initRoute);
                if (tryStep && this.steps.indexOf(tryStep) < this.steps.indexOf(activeStep)) {
                    activeStep = tryStep;
                    redirect = tryStep.route;
                }
            }
        }

        this.setActiveStep(activeStep);

        if (redirect) {
            this.redirectRoute.next(redirect);
        }
    }

    checkIfLicenseStepNeeded(): boolean {
        if (this.application.needAppointment && this.application.allowToSelectAppointmentStates) {
            return true;
        }
    }
}
