import { Injectable } from '@angular/core';
import { RestService } from '../../../../services/rest.service';
import { Producer } from '../../../../shared/models/producer';
import { IClientItemDTO, IClientListDTO } from '../../../../shared/models/common.dto';
import { Observable, BehaviorSubject } from 'rxjs';
import { AgentSearchInput } from './agent-search-input';

@Injectable({
  providedIn: 'root'
})
export class AgentService extends RestService {

    private _childAgents = new BehaviorSubject<Producer[]>([]);
    public set childAgents(v: Producer[]) { this._childAgents.next(v); }
    public get childAgents(): Producer[] { return this._childAgents.value; }

    agentSearchResults: Producer[];

    searchProducers(input: AgentSearchInput): Observable<IClientListDTO<Producer>> {
        let relativeUrl = 'Producer/Search';

        return this.post<IClientListDTO<Producer>>(relativeUrl, input);
    }

}
