import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { DocumentUpload } from '../shared/models/document';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RestService {
    constructor(private http: HttpClient, private config: AppConfigService) { }

    public get<T>(relativeUrl: string): Observable<any> {
        let url = environment.apiUrl + relativeUrl;
        return this.http.get<T>(url);
    }

    public post<T>(relativeUrl: string, data: any): Observable<any> {
        return this.http.post<T[]>(environment.apiUrl + relativeUrl, data);
    }

    public postFile<T>(relativeUrl: string, formData: DocumentUpload): Observable<any> {

        let params = new HttpParams();
        const options = {
            params: params,
            reportProgress: true,
            observe: 'events'
        };

        const req = new HttpRequest('POST', environment.apiUrl + relativeUrl, formData, options);
        return this.http.request<T>(req);
    }

    public patch<T>(relativeUrl: string, data: any): Observable<any> {
        let headers;
        if (typeof (data) === "string") {
            data = "\"" + data + "\"";
            headers = { headers: { "Content-Type": "application/json" } };
        }
        return this.http.patch<T[]>(environment.apiUrl + relativeUrl, data, headers);
    }
}
