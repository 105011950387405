import { DocumentRequirementType } from '../enums/general';

export class ApplicationDocument {
    id: number;
    title: string;
    type: string | DocumentRequirementType;
    editor: string;
    originalCopy: string; //file name
    updatedCopy: string;
    createdTime: Date;
    signedTime: Date;
    uri: string;

    constructor(init?: Partial<ApplicationDocument>) {
        if (init) {
            this.id = init.id;
            this.title = init.title;
            this.type = DocumentRequirementType[init.type];
            this.editor = init.editor;
            this.originalCopy = init.originalCopy;
            this.updatedCopy = init.updatedCopy;
            this.createdTime = init.createdTime;
            this.signedTime = init.signedTime;
        }
    }
}

export class DocumentUpload {
    bizUnitId: number;
    applicationID: string;
    files: DocumentFileBody[];
}

export class DocumentFileBody {
    document: ApplicationDocument;
    data: string;

    constructor(init?: Partial<DocumentFileBody>) {
        Object.assign(this, init);
    }
}
