import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalService {

    private modals: any[] = [];
    private loadingId = 'loading-overlay';
    private messageId = 'message-overlay';

    messageText: string = "";

    add(modal: any) {
        // add modal to array of active modals
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string) {
        // open modal specified by id
        let modal: any = this.modals.filter(x => x.id === id)[0];
        modal.open();
    }

    close(id: string) {
        // close modal specified by id
        let modal: any = this.modals.filter(x => x.id === id)[0];
        modal.close();
    }

    private checkIfOpen(): boolean {
        return document.body.classList.contains('pv-modal-open');
    }

    loading(show: boolean) {
        if (show) {
            this.open(this.loadingId);
        }
        else {
            this.close(this.loadingId);
        }
    }

    message(msg: string) {
        if (this.loading) {
            this.loading(false);
        }

        if (this.checkIfOpen()) {
            this.messageText += msg;
        }
        else {
            this.messageText = msg;
            this.open(this.messageId);
        }
    }
}
