import { RegedOrderStatus } from '../enums/general';
import { LicenseDetail } from './license-detail';

export class License {
    id: number;
    npn: number;

    licenseNumber: string;
    licenseType: string;
    details: LicenseDetail[];
    class: string;
    classCode: number;
    active: boolean;
    stateCode: string;
    editTime: Date;
    effectiveDate: string;
    effectiveDateTime: Date;
    expirationDate: string;
    expirationDateTime: Date;
    branchId: string;
    residencyStatus: string;

    get loaDescription() {
        if (this.details) {
            return Array.from(this.details.map(x => x.description)).join(', ').toLocaleLowerCase();
        }
    }

    selectedForApplication = false;

    constructor(api: Partial<License>) {
        this.id = api.id;
        this.npn = api.npn;
        this.licenseNumber = api.licenseNumber;
        this.licenseType = api.licenseType;
        this.details = api.details.map(x => new LicenseDetail(x));
        this.class = api.class;
        this.classCode = api.classCode;
        this.active = api.active;
        this.stateCode = api.stateCode;
        this.editTime = api.editTime;
        this.effectiveDate = api.effectiveDate;
        this.expirationDate = api.expirationDate;
        this.effectiveDateTime = api.effectiveDate ? new Date(api.effectiveDate.replace('Z', '')) : null;
        this.expirationDateTime = api.expirationDate ? new Date(api.expirationDate.replace('Z', '')) : null;
        this.branchId = api.branchId;
        this.residencyStatus = api.residencyStatus;
    }
}
