import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { CdkTableModule } from '@angular/cdk/table';
//import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppConfigService } from './services/app-config.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserService } from './services/user.service';
import { ModalComponent } from './modal/modal.component';
import { DashboardComponent } from './applications/dashboard/dashboard.component';
import { ApplicationComponent } from './applications/application/application.component';
import { ApplicationsComponent } from './applications/applications.component';
import { InformationComponent } from './applications/application/steps/information/information.component';
import { NavComponent } from './applications/application/nav/nav.component';
import { DocumentsComponent } from './applications/application/steps/documents/documents.component';
import { ESignComponent } from './applications/application/steps/e-sign/e-sign.component';
import { AgentsComponent } from './applications/application/steps/agents/agents.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SsnMaskPipe } from './shared/form/ssn-mask.pipe';
import { AgentsTableComponent } from './applications/application/steps/agents/agents-table/agents-table.component';
import { PhoneMaskDirective } from './shared/form/phone-mask.directive';
import { CompletedComponent } from './applications/application/steps/completed/completed.component';
import { DeclinedComponent } from './applications/application/steps/declined/declined.component';
import { StateSelectModule } from './shared/form/state-select/state-select.module';
import { ApprovedComponent } from './applications/application/steps/approved/approved.component';
import { StateLicenseComponent } from './applications/application/steps/state-license/state-license.component';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './auth.interceptor';
import { LoginComponent } from './login/login.component';

//export function appInit(appConfigService: AppConfigService) {
//    return () => appConfigService.load();
//}

const oktaAuth = new OktaAuth({
    issuer: environment.oktaIssuer,
    clientId: environment.oktaClientId,
    redirectUri: window.location.origin + '/login/callback'
});

@NgModule({ declarations: [
        AppComponent,
        ModalComponent,
        DashboardComponent,
        ApplicationComponent,
        ApplicationsComponent,
        InformationComponent,
        NavComponent,
        StateLicenseComponent,
        DocumentsComponent,
        ESignComponent,
        AgentsComponent,
        SsnMaskPipe,
        AgentsTableComponent,
        PhoneMaskDirective,
        CompletedComponent,
        DeclinedComponent,
        ApprovedComponent,
        LoginComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSortModule,
        CdkTableModule,
        //MatPaginatorModule,
        StateSelectModule,
        BrowserAnimationsModule,
        OktaAuthModule.forRoot({ oktaAuth })], providers: [UserService, AppConfigService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, provideHttpClient(withInterceptorsFromDi()),] })
export class AppModule { }
