import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../application.service';
import { ModalService } from '../../../../modal/modal.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-e-sign',
  templateUrl: './e-sign.component.html',
  styleUrls: ['./e-sign.component.scss']
})
export class ESignComponent implements OnInit {

    docusignStarted: boolean = false;
    checkingStatus: boolean = false;
    status: string;
    url: string;
    docuSignWindow: Window;
    statusCheckTimeout: any;

    constructor(private appService: ApplicationService, private modal: ModalService) { }

    ngOnInit() {
        this.modal.loading(false);
        this.checkStatus();
    }

    launchDocuSign() {
        if (this.url) {
            this.trackWindow();
        }
        else {
            this.modal.loading(true);
            this.appService.startDocuSign().subscribe(resData => {
                if (resData && resData.status == 0) {
                    this.status = "Started";
                    this.docusignStarted = true;
                    this.url = resData.item;
                    this.updateProgress();
                    this.trackWindow();
                }
                else if (resData.status == 204) {
                    this.appService.completeApplication();
                }
                else {
                    alert(resData.message);
                }
                this.modal.loading(false);
            });
        }
    }

    trackWindow() {
        this.docuSignWindow = window.open(this.url, '_blank');

        var _this = this;
        this.statusCheckTimeout = setInterval(function () {
            let status = localStorage.getItem("DocuSignStatus");
            if (status == _this.appService.application.displayID.toString()) {
                localStorage.setItem("DocuSignStatus", "");
                clearInterval(_this.statusCheckTimeout);
                _this.docuSignWindow.close();
                _this.completeApplication();
            }
        }, 5000);
    }

    updateProgress() {
        // check if docusign hasn't been started (prog status not 'esignature' yet, status is still "documents" from previous step completion
        if (this.appService.application.progressStatus != this.appService.activeStep.route) {
            let status = this.appService.activeStep.route;
            this.appService.updateProgress(status).subscribe(resData => {
                if (resData && resData.status == 0) {
                    this.appService.application.progressStatus = status;
                }
            });
        }
    }

    checkStatus() {
        if (this.appService.application.progressStatus == "ESignature") {
            this.checkingStatus = true;
            this.appService.validateDocuSign().subscribe(resData => {
                if (resData && resData.status == 0) {
                    this.handleStatus(resData.item);
                }
                else {
                    this.checkingStatus = false;
                }
            });
        }
    }

    handleStatus(status: string) {
        this.docusignStarted = true;
        this.status = status;
        if (status == "completed") {
            this.appService.completeApplication();
        }
        else {
            this.checkingStatus = false;
        }
    }

    completeApplication() {
        this.checkStatus();
    }
}
