export class Address {
    id: number;
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: string;
    editTime: Date;
    isBusinessAddress: boolean = false;
    isMailingAddress: boolean = false;
    isResidentialAddress: boolean = false;

    constructor(api?: Partial<Address>) {
        if (api) {
            this.id = api.id;
            this.line1 = api.line1;
            this.line2 = api.line2;
            this.city = api.city;
            this.state = api.state;
            this.zip = api.zip;
            this.editTime = api.editTime;
            this.isBusinessAddress = api.isBusinessAddress;
            this.isMailingAddress = api.isMailingAddress;
            this.isResidentialAddress = api.isResidentialAddress;
        }
    }
}
