<h3>eSign Documents</h3>

<ng-container *ngIf="!checkingStatus">
  <p *ngIf="docusignStarted">Click below to continue reviewing and signing the onboarding documents.</p>
  <p *ngIf="!docusignStarted">You will now be redirected to sign documents to finalize the onboarding process.</p>
  <p>Warning: your browser might block the popup.</p>
  <p class="center">
    <a id="launch-docusign" class="button" (click)="launchDocuSign()" title="Opens a new window">Continue to Documents</a>
  </p>

  <div class="center" *ngIf="docusignStarted">
    <p>When you have completed the DocuSign process, click below to finalize the Application process!</p>
    <a class="button float-right finalize-button" (click)="completeApplication()">Finalize Application</a>
  </div>
</ng-container>
<ng-container *ngIf="checkingStatus">
  <p>Checking document status...</p>
</ng-container>
