import { AppointmentState } from './appointment-state';
import { BizUnit } from './biz-unit';
import { Producer } from './producer';
import { AuditLog } from './audit-log';
import { ApplicationDocument } from './document';
import { AuditLogStatus, AuditLogCategory } from '../enums/audit-log';
import { Carrier } from './carrier';

export class Application {
    id: string;
    displayID: number;
    loginId: number;

    progressStatus: string;
    status: string | AuditLogStatus;

    editTime: Date;

    bizUnit: BizUnit;
    carriers: Carrier[];
    appointmentStates: AppointmentState[] = [];
    documents: ApplicationDocument[];
    logs: AuditLog[];

    producer: Producer;

    needAppointment = true;
    allowToAddAgent: boolean;
    allowToSelectAppointmentStates = true;

    get createdDate(): Date {
        let createdLog = this.logs.find(x => x.category == AuditLogCategory.Status && x.message == AuditLogStatus[AuditLogStatus.AppCreate]);
        if (createdLog) {
            return createdLog.editTime;
        }
    }

    get displayStatus() {
        switch (this.status) {
            case AuditLogStatus.AppCreate: return "New";
            case AuditLogStatus.AppReturn: return "Returned";
            case AuditLogStatus.AppUpdate: return "In Progress";
            case AuditLogStatus.AppTransmit: return "Transmitted";
            case AuditLogStatus.AppDecline: return "Declined";
            case AuditLogStatus.AppApprove: return "Approved";
            default: return this.status;
        }
    }

    constructor(api: Partial<Application>) {
        this.id = api.id;
        this.displayID = api.displayID;
        this.loginId = api.loginId;
        this.progressStatus = api.progressStatus;
        this.status = AuditLogStatus[api.status];
        this.editTime = api.editTime;
        this.bizUnit = new BizUnit(api.bizUnit);
        this.carriers = api.carriers;
        this.appointmentStates = api.appointmentStates ? api.appointmentStates.map(x => new AppointmentState(x)) : null;
        this.documents = api.documents ? api.documents.map(x => new ApplicationDocument(x)) : null;
        this.logs = api.logs ? api.logs.map(x => new AuditLog(x)) : null;
        this.producer = new Producer(api.producer);

        this.allowToAddAgent = api.allowToAddAgent;
        this.allowToSelectAppointmentStates = api.allowToSelectAppointmentStates;
        this.needAppointment = api.needAppointment;
    }
}

export class ShortApplication {
    id: string;
    displayID: number;
    loginId: number;

    progressStatus: string;
    status: string | AuditLogStatus;

    editTime: Date;

    bizUnit: BizUnit;
    carriers: Carrier[];
    logs: AuditLog[];

    get createdDate(): Date {
        let createdLog = this.logs.find(x => x.category == AuditLogCategory[AuditLogCategory.Status] && x.message == AuditLogStatus[AuditLogStatus.AppCreate]);
        if (createdLog) {
            return createdLog.editTime;
        }
    }

    get carriersList(): string {
        if (this.carriers) {
            return Array.from(this.carriers.map(x => x.name)).join(', ');
        }

        return "";
    }

    get displayStatus() {
        switch (this.status) {
            case AuditLogStatus.AppCreate: {
                    switch (this.progressStatus) {
                        case "Opened": return "Opened";
                        case "ProducerInformation": return "Contact Information Enterted";
                        case "StateLicense": return "Application States Selected";
                        case "AddAgents": return "Agents Added";
                        case "AddDocuments": return "Ready for eSigning";
                        case "ESignature": return "DocuSign Process Started";
                        case "Completed": return "Completed";
                        default: return "New";
                }
            }
            case AuditLogStatus.AppReturn: return "Returned";
            case AuditLogStatus.AppReviewing: return "Under Review";
            case AuditLogStatus.AppUpdate: return "In Progress";
            case AuditLogStatus.AppTransmit: return "Transmitted";
            case AuditLogStatus.AppDecline: return "Declined";
            case AuditLogStatus.AppApprove: return "Approved";
            default: return this.status;
        }
    }

    constructor(api: Partial<Application>) {
        this.id = api.id;
        this.displayID = api.displayID;
        this.loginId = api.loginId;
        this.progressStatus = api.progressStatus;
        this.status = AuditLogStatus[api.status];
        this.editTime = api.editTime;
        this.bizUnit = new BizUnit(api.bizUnit);
        this.logs = api.logs;
        this.carriers = api.carriers.map(x => new Carrier(x));
    }
}
