import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../../modal/modal.service';
import { ApplicationService } from '../../application.service';
import { AuditLogStatus } from '../../../../shared/enums/audit-log';

@Component({
    selector: 'app-completed',
    templateUrl: './completed.component.html',
    styleUrls: ['./completed.component.scss']
})
export class CompletedComponent implements OnInit {

    statuses: any = AuditLogStatus;
    bizUnitMessage = "Thank you for completing the Producer Onboarding Application for Tokio Marine "
            + this.appService.application.bizUnit.name + ". Please allow 10 business days once all information is received to complete the process.";
    loaded = false;

    constructor(public appService: ApplicationService, private modal: ModalService) { }

    ngOnInit() {
        this.modal.loading(false);
        localStorage.setItem("DocuSignStatus", this.appService.application.displayID.toString());

        this.appService.getBizUnitVerbiage(this.appService.application.bizUnit.id, 'AppComplete').subscribe(res => {
            if (res.status === 0 && res.item) {
                this.bizUnitMessage = res.item;
            }

            this.loaded = true;
        });
    }

}
