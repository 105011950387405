import { Component, OnInit } from '@angular/core';
import { FileService } from '../../../../services/file.service';
import { UserService } from '../../../../services/user.service';
import { ApplicationService } from '../../application.service';
import { DocumentRequirementType } from '../../../../shared/enums/general';
import { ModalService } from '../../../../modal/modal.service';
import { ApplicationDocument } from '../../../../shared/models/document';
import { Observable } from 'rxjs';
import { tap, map} from 'rxjs/operators';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

    percentDone: number = 0;
    uploading: boolean = false;
    uploadMsg: string;

    bizUnitMessage$: Observable<string>;

    constructor(private fileService: FileService, private userService: UserService, public appService: ApplicationService, private modal: ModalService) { }

    ngOnInit() {
        this.appService.documents = this.appService.application.documents.filter(x => x.type == DocumentRequirementType.UserUploaded);

        this.bizUnitMessage$ = this.appService.getBizUnitVerbiage(this.appService.application.bizUnit.id, 'Document')
            .pipe(
                tap(data => console.log('getBizUnitVerbiageForDocument: ', data)),
                map(data => (data.status === 0) ? data.item : data.message)
            );

        this.modal.loading(false);
    }

    // At the drag drop area
    // (drop)="onDropFile($event)"
    onDropFile(event: DragEvent) {
        event.preventDefault();
        this.uploading = true;
        this.uploadFile(event.dataTransfer.files);
        document.getElementById('drop-target').style.backgroundColor = "#ffe062";
    }

    // At the drag drop area
    // (dragover)="onDragOverFile($event)"
    onDragOverFile(event) {
        event.stopPropagation();
        event.preventDefault();
        document.getElementById('drop-target').style.backgroundColor = "#bce2f7";
    }

    onDragOutFile(event: DragEvent) {
        event.stopPropagation();
        event.preventDefault();
        document.getElementById('drop-target').style.backgroundColor = "#ddd";
    }

    // At the file input element
    // (change)="selectFile($event)"
    selectFile(event) {
        this.uploadFile(event.target.files);
    }

    browseFile(event: any) {
        this.uploadFile(event.target.files);
    }

    uploadFile(files: FileList) {
        if (files.length == 0) {
            console.log("No file selected!");
            return
        }

        this.uploadMsg = "";
        this.uploading = true;

        this.fileService.readFile(files).then(data => {

            for (let i = 0; i < files.length; i++) {
                let name = files[i].name;
                let check = this.appService.documents.find(x => x.originalCopy == name);
                if (check) {
                    if (confirm('There is already a file with this name, are you sure you want to overwrite it?')) {

                        check.editor = this.userService.getUserEmail;
                        this.doFileUpload(check, data, true);
                    }
                }
                else {
                    let doc = new ApplicationDocument();
                    doc.title = name;
                    doc.originalCopy = name;
                    doc.type = DocumentRequirementType.UserUploaded;
                    doc.editor = this.userService.appEmail;

                    this.doFileUpload(doc, data, false);
                }
            }
        });
    }

    doFileUpload(doc: ApplicationDocument, data: string, overwrite: boolean) {
        this.fileService.uploadFile(data, doc, this.appService.application.bizUnit.id, this.appService.application.id)
            .subscribe(
                res => {
                    this.uploading = false;
                    if (res.status == 0) {
                        doc.id = res.dataList[0];
                        if (!overwrite) {
                            this.appService.documents.push(doc)
                        }

                        document.getElementById('drop-target').style.backgroundColor = "#92ce8b";
                        this.uploadMsg = "Uploaded Successfully!";
                        setTimeout(() => {
                            document.getElementById('drop-target').style.backgroundColor = "#DDD";
                            this.uploadMsg = "";
                        }, 3000);
                    }
                    else {
                        this.uploadMsg = res.message;
                    }
                },
                err => {
                    document.getElementById('drop-target').style.backgroundColor = "#f5a08a";
                    this.uploadMsg = err.statusText;
                    this.uploading = false;
                }
            );
    }

    deleteFile(doc: ApplicationDocument) {
        this.modal.loading(true);
        this.fileService.deleteFile(doc, this.appService.application.id).subscribe(resData => {
            if (resData && resData.status == 0) {
                let index = this.appService.documents.indexOf(doc);
                this.appService.documents.splice(index, 1);
            }
            else {
                alert(resData.message);
            }
            this.modal.loading(false);
        });
    }

    getDocumentLink(doc: ApplicationDocument) {
        if (doc.uri) {
            window.open(doc.uri, '_blank');
        }
        else {
            this.fileService.getDocumentLink(doc, this.appService.application.id).subscribe(resData => {
                if (resData && resData.status == 0 && resData.dataList[0] != "about:blank") {
                    doc.uri = resData.dataList[0];
                    window.open(doc.uri, '_blank');
                }
            });
        }
    }

    completeStep() {
        this.modal.loading(true);

        this.appService.completeSaveAndNext();
    }
}
