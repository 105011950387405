
  <div id="agents-table-container">
    <ng-container *ngIf="!(agentService.childAgents && agentService.childAgents.length > 0)">
      <p>There are currently no Agents added.</p>
    </ng-container>

    <mat-table *ngIf="agentService.childAgents && agentService.childAgents.length > 0" [dataSource]="agentService.childAgents">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let result" class="proper-name">{{result.fullName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
        <mat-cell *matCellDef="let result">{{result.stateCode}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="npn">
        <mat-header-cell *matHeaderCellDef>NPN</mat-header-cell>
        <mat-cell *matCellDef="let result">{{result.npn}} </mat-cell>
      </ng-container>

      <!--<ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>License Type</mat-header-cell>
        <mat-cell *matCellDef="let result" class="proper-name">{{result.licenseType}}</mat-cell>
      </ng-container>-->

      <ng-container matColumnDef="primary">
        <mat-header-cell *matHeaderCellDef>Primary Agent</mat-header-cell>
        <mat-cell *matCellDef="let result"><a class="button" *ngIf="!result.isPrimaryAgent" (click)="setPrimaryAgent(result)">Set as Primary Agent</a><span *ngIf="result.isPrimaryAgent">Primary Agent</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef>Delete Agent</mat-header-cell>
        <mat-cell *matCellDef="let result"><a class="button warning-btn" (click)="deleteAgent(result)">Delete</a></mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="['name', 'state', 'npn', 'primary', 'delete']"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: ['name', 'state', 'npn', 'primary', 'delete']; index as i" class="{{(row.isPrimaryAgent) ? 'primary-agent' : ''}} {{(i%2 == 0) ? 'even' : 'odd'}}-row"></mat-row>

    </mat-table>

  </div>
