import { Contact } from './contact';
import { License } from './license';

export class Producer {

    id: number;
    contact: Contact;
    licenses: License[];

    npn: number;
    type: string | number;
    stateCode: string;

    parentProducerList: Producer[];

    //Agency
    childProducerList: Producer[] = [];
    fein: string;
    branchId: string;
    taxClass: string;
    companyName: string;
    isBranchAgency: boolean;

    //Agent
    ssn: string;
    firstName: string;
    lastName: string;
    middleName: string;
    isPrimaryAgent = false;
    dateOfBirth: Date;

    fullName: string;

    get license(): License {
        if (this.stateCode) {
            let stateLic = this.licenses.find(x => x.residencyStatus == "R");
            if (stateLic) {
                return stateLic;
            }
        }
        return null;
    }

    get licenseType(): string {
        let lic = this.license;

        if (lic) {
            return lic.licenseType;
        }
    }

    licenseNumber: string 
    
    constructor(api: Partial<Producer>) {
        this.id = api.id;
        this.contact = api.contact ? new Contact(api.contact) : new Contact();
        this.licenses = api.licenses ? api.licenses.filter(x => x.active).map(x => new License(x)).sort((x, y) => {
            if (x.stateCode > y.stateCode) return 1; else return -1;
        }) : null;
        this.npn = api.npn;
        this.companyName = api.companyName;
        this.firstName = api.firstName;
        this.lastName = api.lastName;
        this.middleName = api.middleName;
        this.type = api.type;
        this.stateCode = api.stateCode;
        this.parentProducerList = api.parentProducerList ? api.parentProducerList.map(x => new Producer(x)) : null;
        this.childProducerList = api.childProducerList ? api.childProducerList.map(x => new Producer(x)) : null;
        this.fein = api.fein;
        this.branchId = api.branchId;
        this.taxClass = api.taxClass;
        this.ssn = api.ssn;
        this.isPrimaryAgent = api.isPrimaryAgent;
        this.isBranchAgency = api.isBranchAgency;
        this.dateOfBirth = api.dateOfBirth;

        let name = '';
        if (this.type == "Agent") {
            name = this.firstName;
            if (this.middleName) {
                name += " " + this.middleName;
            }
            name += " " + this.lastName;
        }
        else {
            name = this.companyName;
        }
        this.fullName = name ? name.toLowerCase() : null;

        if (this.license) {
            this.licenseNumber = this.license.licenseNumber;
        }
    }
}

export class ProducerRelationship {
    parentProducerId: number;
    childProducerId: number;
    isPrimaryAgent: boolean;
}
