<!--<div>
  <a class="button" (click)="saveAndCloseApplication()">Save and Close</a>
</div>-->

<app-nav></app-nav>

<div id="nav-route-contain" *ngIf="appService.application">
  <router-outlet></router-outlet>
</div>


