import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApplicationService } from '../../application.service';
import { Subscription } from 'rxjs';
import { License } from '../../../../shared/models/license';
import { ModalService } from '../../../../modal/modal.service';
import { AppointmentState } from '../../../../shared/models/appointment-state';

@Component({
    selector: 'app-state-license',
    templateUrl: './state-license.component.html',
    styleUrls: ['./state-license.component.scss']
})
export class StateLicenseComponent implements OnInit, OnDestroy {

    noLicenses = false;
    residentStateLicenses: License[];
    stateLicenses: License[];
    loaded = false;

    private producerSubscription: Subscription;

    constructor(public appService: ApplicationService, private modal: ModalService) { }

    ngOnInit() {
        console.log('appointment');
        if (this.appService.producer) {
            this.setupLicenses();
        }
        else {
            this.producerSubscription = this.appService.producerLoaded.subscribe(loaded => {
                if (loaded) {
                    this.setupLicenses();
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.producerSubscription) {
            this.producerSubscription.unsubscribe();
        }
    }

    setupLicenses() {
        const appStates = this.appService.application.appointmentStates;
        const lic = this.appService.producer.licenses;

        if (lic && lic.length > 0) {
            for (let i = 0; i < appStates.length; i++) {
                const stateLic = lic.find(x => x.stateCode === appStates[i].stateCode);
                if (stateLic) {
                    stateLic.selectedForApplication = true;
                }
            }

            const domState = this.appService.application.producer.stateCode;
            this.residentStateLicenses = lic.filter(x => x.stateCode === domState);
            this.stateLicenses = lic.filter(x => x.stateCode !== domState);
        }
        else {
            this.noLicenses = true;
        }

        this.loaded = true;
        this.modal.loading(false);
    }


    completeStep() {
        const oldAppts = this.appService.application.appointmentStates;
        let appts: AppointmentState[] = [];
        let licenses = this.residentStateLicenses;
        licenses = licenses.concat(this.stateLicenses);

        for (let i = 0; i < licenses.length; i++) {
            if (licenses[i].selectedForApplication) {
                let existing = oldAppts.find(x => x.licenseId == licenses[i].id);
                if (existing) {
                    appts.push(existing);
                }
                else {
                    let appt = new AppointmentState();
                    appt.applicaitonId = this.appService.application.id;
                    appt.licenseId = licenses[i].id;
                    appt.stateCode = licenses[i].stateCode;
                    appts.push(appt);
                }
            }
        }

        this.appService.application.appointmentStates = appts;

        if (appts.length > 0) {
            this.modal.loading(true);
            this.appService.updateAppointmentStates(appts).subscribe({
                next: res => {
                    if (res) {
                        this.appService.completeSaveAndNext()
                    }
                }
            });
        }
        else {
            alert("Please select at least 1 License to continue");
        }
    }
}
