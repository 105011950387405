import { Inject, Injectable } from '@angular/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { AuthState, UserClaims } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ShortApplication } from '../shared/models/application';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public isAuthenticated$!: Observable<boolean>;
    public name$!: Observable<string>;
    public email!: string | undefined;
    public claims$!: Observable<UserClaims | undefined>;
    //loginPromise: Promise<void>;

    constructor(private _oktaAuthStateService: OktaAuthStateService) {
        this.userLoaded = false;
        this.name$ = this._oktaAuthStateService.authState$.pipe(
            filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
            map((authState: AuthState) => authState.idToken?.claims.name ?? '')
        );

        this.claims$ = this._oktaAuthStateService.authState$.pipe(
            filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),
            map((authState: AuthState) => {
                console.log(authState.idToken?.claims);
                return authState.idToken?.claims;
            })
        );

        this.claims$.pipe(
            map((claims: UserClaims | undefined) => {
                this.email = claims?.email;
            })
        );
        //this.configure();
        //this.oauthService.events.subscribe(e => {
        //    //if (e instanceof OAuthErrorEvent) {
        //    //    const param = e.params as OAuthErrorEventParams;
        //    //    if (param) {
        //    //        //if (param.error === 'access_denied' && param.error_description.indexOf('AADB2C90118') >= 0) {
        //    //        //    // redirect to forgot password flow
        //    //        //    window.location.href = pvAuthService.GetPasswordResetUrl();
        //    //        //} else if (param.error === 'access_denied' && param.error_description.indexOf('AADB2C90091') >= 0) {
        //    //        //    // user has cancelled out of password reset
        //    //        //    //e = new OAuthSuccessEvent('logout');
        //    //        //}
        //    //    }
        //    //}
        //});
        //this.loginPromise = this.oauthService.tryLoginCodeFlow();
        //this.claims;
    }

    ngOnInit() {
        
    }

    

    //public login() {
    //    this.oauthService.initLoginFlow();
    //}

    //public logout() {
    //    this.oauthService.logOut();
    //}

    //public get resetPasswordUrl() {
    //    return this.pvAuthService.GetPasswordResetUrl();
    //}

    userLoaded: boolean = false;
    applications: ShortApplication[];

    appFirstName: string;
    appLastName: string;
    appAgencyName: string;
    appEmail: string;
    appId: number;

    //public get isAuthenticated(): boolean {
    //    let claims = this.claims;
    //    return (claims != null && !this.isExpiredSession);
    //}

    //public get isExpiredSession(): boolean {
    //    let claims = this.claims;
    //    let time = Date.now() / 1000; // time in seconds
    //    if (claims.exp < time) {
    //        //this.logout();
    //        return true;
    //    }
    //    else {
    //        return false;
    //    }
    //}

    //public get getUserId(): string {
    //    const user = this.oktaAuth.getUser();
    //    return JSON.stringify(user, null, 4);
    //}
    //public get getUserId(): string {
    //    let retData = '';
    //    let claims = this.claims;
    //    if (claims) {
    //        retData = claims.tid;
    //    }
    //    return retData;
    //}
    //public get getUserPerferredName(): string {
    //    const user = this.oktaAuth.getUser();
    //    return JSON.stringify(user, null, 4);
    //}
    //public get getUserPerferredName(): string {
    //    let retData = '';
    //    let claims = this.claims;
    //    if (claims) {
    //        retData = claims.name;
    //    }
    //    return retData;
    //}

    public get getUserEmail(): string {
        return this.email;
    }

    //public get getUserEmail(): string {
    //    let retData = '';
    //    let claims = this.claims;
    //    if (claims && claims.emails && claims.emails.length >= 1) {
    //        retData = claims.emails[0];
    //    }
    //    return retData;
    //}

    //private _claims;
    //public get claims(): any {
    //    let claims = this._claims;
    //    if (claims == undefined) {
    //        //claims = this.oauthService.getIdentityClaims();
    //    }

    //    return claims;
    //}

    //private configure() {
    //    //this.oauthService.configure(this.pvAuthService.getAuthConfig());
    //    //this.oauthService.tokenValidationHandler = new NullValidationHandler();
    //    //this.oauthService.loadDiscoveryDocument(DiscoveryDocumentConfig.url);
    //}
}
