import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { IClientItemDTO } from '../shared/models/common.dto';
import { Observable } from 'rxjs';
import { IUserApplicationsDto } from '../shared/models/api-result.dto';

@Injectable({
    providedIn: 'root'
})
export class DataService extends RestService {

    getAllApplications(): Observable<IClientItemDTO<IUserApplicationsDto>> {

        let relativeUrl = `Application/All`;
        return this.get<IClientItemDTO<IUserApplicationsDto>>(relativeUrl);
    }

    getHeartBeat(): Observable<string[]> {
        let relativeUrl = 'application/heartbeat';
        return this.get<string[]>(relativeUrl);
    }
}
