import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../application.service';
import { ModalService } from '../../../../modal/modal.service';

@Component({
  selector: 'app-approved',
  templateUrl: './approved.component.html',
  styleUrls: ['./approved.component.scss']
})
export class ApprovedComponent implements OnInit {

    constructor(public appService: ApplicationService, private modal: ModalService) { }

    ngOnInit() {
        this.modal.loading(false);
    }

}
