<h3>Licenses</h3>

<ng-container *ngIf="loaded">
    <div *ngIf="!noLicenses">
        <p>
            Please select the states in which you want to be considered for appointment. Below are the current states and LOAs in which you hold a license.
        </p>
        <div *ngIf="residentStateLicenses">
            <h4>Resident State Licenses</h4>
            <p *ngFor="let appState of residentStateLicenses">
                <mat-checkbox class="med-gray heading-checkbox" name="res-state-{{appState.stateCode}}-{{appState.licenseNumber}}" [(ngModel)]="appState.selectedForApplication"><span class="state-code">{{appState.stateCode}}</span> - {{appState.loaDescription}}</mat-checkbox>
            </p>
        </div>

        <div *ngIf="stateLicenses">
            <h4>Non-Resident State Licenses</h4>
            <p *ngFor="let appState of stateLicenses">
                <mat-checkbox class="med-gray heading-checkbox" name="state-lic-{{appState.stateCode}}-{{appState.licenseNumber}}" [(ngModel)]="appState.selectedForApplication">{{appState.stateCode}} - {{appState.licenseNumber}} - <span class="proper-name">{{appState.loaDescription}}</span></mat-checkbox>
            </p>
        </div>

        <a class="button float-right page-next" (click)="completeStep()">{{appService.nextStepBtnText}}</a>
    </div>

  <div *ngIf="noLicenses">
    <h4>Sorry, we could not locate any licenses for this Producer</h4>

    <p>No states selected for appointment</p>
  </div>
</ng-container>
