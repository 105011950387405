export class AppointmentState {
    applicaitonId: string;
    licenseId: number;
    stateCode: string;
    isAppointed: boolean;

    dataSource: string;

    constructor(init?: Partial<AppointmentState>) {
        Object.assign(this, init);
    }
}
