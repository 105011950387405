import { Component, OnInit, OnDestroy } from '@angular/core';
import { FlowStatus } from '../../../../shared/models/flow-status';
import { ApplicationService } from '../../application.service';
import { AgentService } from './agent.service';
import { AgentSearchInput } from './agent-search-input';
import { Producer } from '../../../../shared/models/producer';
import { ModalService } from '../../../../modal/modal.service';
import { Subscription } from 'rxjs';
import { ProducerType } from '../../../../shared/enums/general';

@Component({
    selector: 'app-agents',
    templateUrl: './agents.component.html',
    styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit, OnDestroy {

    isAddingAgent: boolean = false;
    npnSearch: boolean;
    hasSearched: boolean = false;

    searchInput: AgentSearchInput;
    isSearching: boolean = false;

    bizUnitMessage: string = "";

    private producerSubscription: Subscription;

    constructor(public appService: ApplicationService, public agentService: AgentService, private modal: ModalService) {
        console.log('agents');
        this.searchInput = new AgentSearchInput();
        if (!this.appService.producerHierarchy) {
            if (this.appService.producer) {
                this.loadProducerHierarchy();
            }
            else {
                this.producerSubscription = this.appService.producerLoaded.subscribe(prod => {
                    this.loadProducerHierarchy();
                })
            }
        }
        switch (this.appService.application.bizUnit.name) {
            case "Credit": this.bizUnitMessage = "Tokio Marine HCC – Credit Group requires all licensed agents to be appointed.";
                break;
            case "USSIC": this.bizUnitMessage = "Tokio Marine HCC – U.S Specialty Insurance Company requires all licensed agents to be appointed.";
                break;
            default: this.bizUnitMessage = "";
                break;
        }

        modal.loading(false);
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        if (this.producerSubscription) {
            this.producerSubscription.unsubscribe();
        }
    }

    loadProducerHierarchy() {
        this.appService.getProducerHierarchy().subscribe(resData => {
            if (resData && resData.status == 0) {
                this.appService.producerHierarchy = new Producer(resData.item);
                this.agentService.childAgents = this.appService.producerHierarchy.childProducerList;
            }
        });
    }
    
    resetSearch(): void {
        this.isAddingAgent = true;
        this.hasSearched = false;
    }

    selectRow(row: Producer) {
        this.appService.addProducerRelationship(row.id).subscribe(resData => {
            if (resData && resData.status == 0) {
                let agents = this.agentService.childAgents.slice();
                agents.push(row);
                this.agentService.childAgents = agents;
                this.hasSearched = false;
                this.isAddingAgent = false;
                this.agentService.agentSearchResults = null;
            }
            else {
                this.modal.message(resData.message);
            }
        });
    }

    searchFromUserInput() {
        let input: AgentSearchInput = new AgentSearchInput();

        if (this.searchInput.npn) {
            this.npnSearch = true;
            input.npn = this.searchInput.npn;
        }
        else {
            if (this.searchInput.lastName || this.searchInput.firstName) {
                input.lastName = this.searchInput.lastName;
                input.firstName = this.searchInput.firstName;
            }
            else {
                alert('Please enter either a Name or NPN to conduct a search');
                return;
            }

            this.npnSearch = false;
        }

        this.agentService.agentSearchResults = null;
        this.agentService.searchProducers(input).subscribe(resData => {
            if (resData && resData.status == 0 && resData.dataList) {
                this.agentService.agentSearchResults = resData.dataList.map(x => new Producer(x));
            }
            this.hasSearched = true;
        });
    }


    addNewProducer() {
        let newAgent = new Producer({ type: ProducerType.Agent, firstName: this.searchInput.firstName, lastName: this.searchInput.lastName, npn: this.searchInput.npn });
    }

    completeStep() {
        this.modal.loading(true);

        if (this.validateStep()) {
            this.appService.completeSaveAndNext();
        }
    }

    validateStep(): boolean {

        return true;
    }
}
