import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/table';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';
import { Application } from '../../shared/models/application';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    hasApplications: boolean = false;
    columns: string[] = ['id', 'businessUnit', 'carriers', 'status', 'createdDate'];
    
    openApplication(row: Application) {
        this.router.navigate(['/Application', row.displayID]);
    }

    constructor(public userService: UserService, private router: Router) { }

    ngOnInit() {
    }

}
