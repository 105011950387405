export enum AuditLogCategory {
    System = 1,
    Status = 2,
    Comment = 3,
}

export enum AuditLogStatus {
    AppCreate = 0,
    AppReturn = 1,
    AppUpdate = 2,
    AppTransmit = 3,
    AppDecline = 4,
    AppApprove = 5,
    AppBackgroundCheck = 6,
    AppCreditCheck = 7,
    AppSecondReview = 8,
    AppArchive = 9,
    AppReviewing = 10,
}
