import { AuditLogCategory } from '../enums/audit-log';

export class AuditLog {
    id: number;
    category: string | AuditLogCategory;
    message: string;
    editor: string;
    editTime: Date;

    constructor(init?: Partial<AuditLog>) {
        this.id = init.id;
        this.category = AuditLogCategory[init.category];
        this.message = init.message;
        this.editor = init.editor;
        this.editTime = init.editTime;
    }
}
