export class FlowStatus {
    public constructor(title: string, route: string, active: boolean = false) {
        this.title = title;
        this.route = route;
        this.active = active;
    }

    public title: string;
    public current: boolean = false;
    public completed: boolean = false;
    public active: boolean = false;
    public route: string;

    public get classList(): string {
        let classList = "";
        if (this.current) {
            classList += "current";
        }
        else if (this.completed) {
            classList += "completed";
        }

        return classList;
    }

    public get nextStepBtnText(): string {
        if (this.completed) {
            return "Update " + this.title;
        }
        else {
            return "Complete " + this.title;
        }
    }
}
