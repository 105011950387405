import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ApplicationService } from '../../application.service';
import { UserService } from '../../../../services/user.service';
import { Subscription } from 'rxjs';
import { Contact } from '../../../../shared/models/contact';
import { Address } from '../../../../shared/models/address';
import { ModalService } from '../../../../modal/modal.service';
import { PhoneNumberFormat } from '../../../../utils/string-transforms';
import { GlobalConsts } from '../../../../utils/constants';

@Component({
    selector: 'app-information',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit, OnDestroy, AfterViewInit {
    ngAfterViewInit(): void {
    }

    mailingSameAsBusiness = false;
    resSameAsMailing = false;
    ssnDisplay: string;

    loaded = false;

    private producerSubscription: Subscription;

    constructor(public appService: ApplicationService, public userService: UserService, private modal: ModalService) {
        this.producerSubscription = this.appService.producerLoaded.subscribe(loaded => {
            if (loaded) {
                this.setupInformation();
            }
        });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        if (this.producerSubscription) {
            this.producerSubscription.unsubscribe();
        }
    }

    setupInformation() {
        let contact = this.appService.producer.contact;
        let biz = contact.businessAddress;
        let mail = contact.mailingAddress;
        let res = contact.residentialAddress;

        console.log('info');

        if (biz.id === mail.id) {
            this.mailingSameAsBusiness = true;
            delete mail.id;
            delete mail.editTime;
            this.updateMailing();
        }

        if (mail.id === res.id) {
            this.resSameAsMailing = true;
            delete res.editTime;
            delete res.id;
            this.updateResidential();
        }

        if (!this.appService.producer.contact.email) {
            this.appService.producer.contact.email = this.userService.appEmail;
        }

        this.modal.loading(false);
        this.loaded = true;
    }

    public get step() {
        return this.appService.activeStep;
    }

    updateMailing() {
        if (this.mailingSameAsBusiness) {
            let c = this.appService.producer.contact;
            c.mailingAddress.line1 = '';
            c.mailingAddress.line2 = '';
            c.mailingAddress.city = '';
            c.mailingAddress.state = '';
            c.mailingAddress.zip = '';
        }
    }

    updateResidential() {
        if (this.resSameAsMailing) {
            let c = this.appService.producer.contact;
            c.residentialAddress.line1 = '';
            c.residentialAddress.line2 = '';
            c.residentialAddress.city = '';
            c.residentialAddress.state = '';
            c.residentialAddress.zip = '';
        }
    }

    completeStep() {
        this.modal.loading(true);
        if (this.validateStep()) {
            let c = new Contact(this.appService.producer.contact);
            c.addresses = [];
            this.appService.updatePartialContact(c).subscribe({
                next: contRes => {
                    if (contRes && contRes.status === 0) {
                        this.appService.callGetProducer();
                        this.appService.completeSaveAndNext();
                    }
                },
                error: err => {
                    this.modal.loading(false);
                }
            });
        }
        else {
            this.modal.loading(false);
        }
    }

    // only allowing edits to some fields, comment out checking the locked down fields
    validateStep(): boolean {
        //re-check phone numbers to prevent copy+paste errors
        //biz phone
        //let val = PhoneNumberFormat(this.appService.producer.contact.bizPhone);
        //if (val) {
        //    let valid = GlobalConsts.phoneNumRegex.test(val);
        //    if (!valid) {
        //        alert('Business Phone number format is incorrect (###-###-####)');
        //        return;
        //    }
        //    this.appService.producer.contact.bizPhone = val;
        //}
        //cell phone
        var val = PhoneNumberFormat(this.appService.producer.contact.cellPhone);
        if (val) {
            let valid = GlobalConsts.phoneNumRegex.test(val);
            if (!valid) {
                alert('Cell Phone number format is incorrect (###-###-####)');
                return;
            }
            this.appService.producer.contact.cellPhone = val;
        }
        //other phone
        val = PhoneNumberFormat(this.appService.producer.contact.otherPhone);
        if (val) {
            let valid = GlobalConsts.phoneNumRegex.test(val);
            if (!valid) {
                alert('Other Phone number format is incorrect (###-###-####)');
                return;
            }
            this.appService.producer.contact.otherPhone = val;
        }

        //var test = this.validateAddress(this.appService.producer.contact.businessAddress);
        //if (test !== '') {
        //    alert('Business Address error: \r\r' + test);
        //    return false;
        //}

        //if (!this.mailingSameAsBusiness) {
        //    test = this.validateAddress(this.appService.producer.contact.mailingAddress);
        //    if (test !== '') {
        //        alert('Mailing Address error: \r\r' + test);
        //        return false;
        //    }
        //}

        //if (!this.resSameAsMailing) {
        //    test = this.validateAddress(this.appService.producer.contact.residentialAddress);
        //    if (test !== '') {
        //        alert('Residential Address error: \r\r' + test);
        //        return false;
        //    }
        //}

        return true;
    }

    validateAddress(addr: Address): string {
        let ret = '';
        if (addr.line1 === "") {
            ret += 'Address Line 1 is required\r';
        }
        if (!addr.state) {
            ret += 'State is required\r';
        }
        if (addr.city === "") {
            ret += 'City is required\r';
        }
        if (addr.zip === "") {
            ret += 'Zip is required';
        }

        return ret;
    }
}
