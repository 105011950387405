import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './applications/dashboard/dashboard.component';
import { ApplicationsComponent } from './applications/applications.component';
import { ApplicationComponent } from './applications/application/application.component';
import { InformationComponent } from './applications/application/steps/information/information.component';
import { StateLicenseComponent } from './applications/application/steps/state-license/state-license.component';
import { DocumentsComponent } from './applications/application/steps/documents/documents.component';
import { ApplicationNavGuard } from './applications/application/nav.guard';
import { ESignComponent } from './applications/application/steps/e-sign/e-sign.component';
import { AgentsComponent } from './applications/application/steps/agents/agents.component';
import { CompletedComponent } from './applications/application/steps/completed/completed.component';
import { DeclinedComponent } from './applications/application/steps/declined/declined.component';
import { ApprovedComponent } from './applications/application/steps/approved/approved.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { LoginComponent } from './login/login.component';


export const routes: Routes = [
    { path: '', component: LoginComponent, pathMatch: 'full' },
    {
        path: 'login/callback',
        component: OktaCallbackComponent,
    },
    {
        path: 'Applications', component: ApplicationsComponent, canActivate: [OktaAuthGuard], children: [
            { path: 'Dashboard', component: DashboardComponent },
            { path: '', redirectTo: 'Dashboard', pathMatch: 'full' }
        ]
    },
    {
        path: 'Application/:id', component: ApplicationComponent, canActivate: [OktaAuthGuard], children: [
            //each step of application will go here...
            { path: 'ProducerInformation', component: InformationComponent, canActivate: [ApplicationNavGuard], pathMatch: 'full' },
            { path: 'StateLicense', component: StateLicenseComponent, canActivate: [ApplicationNavGuard], pathMatch: 'full' },
            { path: 'AddAgents', component: AgentsComponent, canActivate: [ApplicationNavGuard], pathMatch: 'full' },
            { path: 'AddDocuments', component: DocumentsComponent, canActivate: [ApplicationNavGuard], pathMatch: 'full' },
            { path: 'ESignature', component: ESignComponent, canActivate: [ApplicationNavGuard], pathMatch: 'full' },
            { path: 'Completed', component: CompletedComponent, canActivate: [ApplicationNavGuard], pathMatch: 'full' },
            { path: 'Declined', component: DeclinedComponent, canActivate: [ApplicationNavGuard], pathMatch: 'full' },
            { path: 'Approved', component: ApprovedComponent, canActivate: [ApplicationNavGuard], pathMatch: 'full' },
            //{ path: '', redirectTo: 'ProducerInformation', pathMatch: 'full' }
        ]
    },
    { path: '**', redirectTo: 'Applications', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
