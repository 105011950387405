export enum ProducerType {
    Agency,
    Agent
}

export enum ApplicationProgress {
    None,
    Opened,
    ProducerInformation,
    StateLicense,
    AddAgents,
    AddDocuments,
    ESignature,
    Completed
}

export enum DocumentRequirementType {
    Optional = 0,
    All = 1,
    MustSign = 2,
    MustHave = 3,
    DocuSignSummary = 4, //informational
    UserUploaded = 5
}

//export enum FederalTaxClass {
//    CCorporation = "C-Corporation",
//    SCorporation = "S-Corporation",
//    Partnership = "Partnership",
//    TrustEstate = "Trust/Estate",
//    IndividualSole = "Individual/Sole",
//    ProprietorSinglememberLLC = "Proprietor/Single-member LLC"
//}


export enum RegedOrderStatus {
    NotAppointed,
    Appointed,
    Terminated,

    RegedOrderSubmited,
    UnknownRegedStatus,
    UnknownLicenseDetailId,
    RegedError,
    RegedValidationError,
    NiprValidationError,
    SentToNiprAndWaiting,
    CompletedAsNiprAccepted,
    CompletedAsNiprRejected,

    Initiated,
    AttachedToApplication,
}
