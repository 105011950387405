<h3>Document Upload</h3>

<p>{{bizUnitMessage$ | async}}</p>

<div id="file-container">
  <div class="file-half">
    <h4>Uploaded Files</h4>
    <p *ngIf="!appService.documents || appService.documents.length == 0">
      <span class="italics">No files uploaded</span>
    </p>
    <p *ngFor="let doc of appService.documents">
      <a class="delete-btn" (click)="deleteFile(doc)"></a><a (click)="getDocumentLink(doc)" title="{{doc.createdTime | date:'mediumDate'}}">{{doc.title}}</a>
    </p>
  </div>
  <div class="file-half">
    <div id="drop-target" (drop)="onDropFile($event)" (dragover)="onDragOverFile($event)">
      <ng-container *ngIf="!uploading">
        <h4>Drop files here</h4>
        <p>{{uploadMsg}}</p>
      </ng-container>
      <ng-container *ngIf="uploading">
        <h4>Uploading...</h4>
      </ng-container>
    </div>
    <input type="file" id="file-browse-button" value="Browse" (change)="browseFile($event)" />
  </div>
</div>

<a class="button float-right page-next" (click)="completeStep()">{{appService.nextStepBtnText}}</a>
