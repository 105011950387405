import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../application.service';
import { Router, NavigationEnd } from '@angular/router';
import { ApplicationProgress } from '../../../shared/enums/general';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

    constructor(public appService: ApplicationService, private router: Router) { }

    private routeSub;
    private redirectSub;
    private activeRoute: string;

    ngOnInit() {
        this.routeSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                let route = event.url.split('/').reverse()[0];
                if (route != this.activeRoute) {
                    let step = this.appService.getStep(route);
                    if (step && this.appService.activeStep != step) {
                        this.appService.setActiveStep(step);
                        this.activeRoute = route;
                    }
                }
            }
        });

        this.redirectSub = this.appService.redirectRoute.subscribe((newRoute) => {
            this.navToStep(newRoute);
        });
    }

    ngOnDestroy() {
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
        if (this.redirectSub) {
            this.redirectSub.unsubscribe();
        }
    }

    navToStep(route: string) {
        this.router.navigate(['Application', this.appService.application.displayID, route]);
    }
}
