<h2 class="inline">Onboarding Dashboard</h2>

<ng-container *ngIf="userService.userLoaded">
  <div class="table-contain" *ngIf="userService.applications">
    <p>Available Applications:</p>

    <mat-table [dataSource]="userService.applications">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef>Application ID</mat-header-cell>
        <mat-cell *matCellDef="let app">{{app.displayID}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="businessUnit">
        <mat-header-cell *matHeaderCellDef>TM Business Unit</mat-header-cell>
        <mat-cell *matCellDef="let app">{{app.bizUnit.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="carriers">
        <mat-header-cell *matHeaderCellDef>Appointing Carrier</mat-header-cell>
        <mat-cell *matCellDef="let app">{{app.carriersList}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
        <mat-cell *matCellDef="let app">{{app.displayStatus}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef>Created Date</mat-header-cell>
        <mat-cell *matCellDef="let app">{{app.createdDate | date:'mediumDate'}} </mat-cell>
      </ng-container>

      <mat-header-row *cdkHeaderRowDef="columns"></mat-header-row>
      <mat-row *cdkRowDef="let row; columns: columns; index as i" class="clickable-row {{(i%2 == 0) ? 'even' : 'odd'}}-row" (click)="openApplication(row)"></mat-row>

    </mat-table>
  </div>

  <div *ngIf="!userService.applications">
    <h3>You don't currently have any applications.</h3>
  </div>

</ng-container>

<ng-container *ngIf="!userService.userLoaded">
  <h3 style="margin-top: 50px;">Loading...</h3>
</ng-container>
