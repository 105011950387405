import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestService } from './rest.service';
import { DocumentUpload, DocumentFileBody, ApplicationDocument } from '../shared/models/document';
import { IClientItemDTO, IClientListDTO } from '../shared/models/common.dto';

@Injectable({
    providedIn: 'root'
})
export class FileService extends RestService {
    
    readFile(files: FileList): Promise<string> {
        return new Promise(resolve => {

            var reader = new FileReader();
            // Read file content on file loaded event
            reader.onload = function (event: any) {
                resolve(event.target.result.toString().replace(/^data:.+;base64,/, ''));
            };

            // Convert data to base64 
            reader.readAsDataURL(files[0]);
        });
    }

    uploadFile(fileString: string, doc: ApplicationDocument, bizUnitId: number, applicationId: string): Observable<IClientListDTO<number>> {

        console.log('files');
        let relativeUrl = "CloudStorage/Application/Save";

        let upload: DocumentUpload = new DocumentUpload();
        upload.applicationID = applicationId;
        upload.bizUnitId = bizUnitId;
        upload.files = [];
        upload.files.push(new DocumentFileBody({ document: doc, data: fileString }));

        return this.post<IClientListDTO<number>>(relativeUrl, upload);
    }

    getDocumentLink(doc: ApplicationDocument, appId: string): Observable<IClientListDTO<string>> {
        let relativeUrl = 'CloudStorage/Uris';

        let input = {
            'applicationID': appId,
            'fileType': 0,
            'fileNames': [
                doc.originalCopy
            ]
        };

        return this.post<IClientListDTO<string>>(relativeUrl, input);
    }

    deleteFile(doc: ApplicationDocument, appId: string): Observable<IClientItemDTO<number>> {
        let relativeUrl = 'CloudStorage/Delete';

        let input = {
            'applicationID': appId,
            'fileType': 0,
            'fileNames': [
                doc.originalCopy
            ]
        }

        return this.post<IClientItemDTO<number>>(relativeUrl, input);
    }
}
