import { Address } from './address';

export class Contact {
    id: number;
    bizPhone: string;
    cellPhone: string;
    otherPhone: string;
    fax: string;
    email: string;
    website: string;
    preferredContact: string;
    editor: string;
    editTime: Date;

    addresses: Address[]; //direct from API

    businessAddress: Address = new Address({ isBusinessAddress: true });
    mailingAddress: Address = new Address({ isMailingAddress: true });
    residentialAddress: Address = new Address({ isResidentialAddress: true });


    constructor(api?: Partial<Contact>) {
        if (api) {
            this.id = api.id;
            this.bizPhone = api.bizPhone;
            this.cellPhone = api.cellPhone;
            this.otherPhone = api.otherPhone;
            this.fax = api.fax;
            this.email = api.email;
            this.website = api.website;
            this.preferredContact = api.preferredContact;
            this.editor = api.editor;
            this.editTime = api.editTime;
            this.addresses = api.addresses ? api.addresses.map(x => new Address(x)) : [];
        }

        if (this.addresses) {
            let addresses = this.addresses;
            let biz = addresses.find(x => x.isBusinessAddress == true);
            let mail = addresses.find(x => x.isMailingAddress == true);
            let res = addresses.find(x => x.isResidentialAddress == true);

            if (biz) {
                biz.isMailingAddress = false;
                biz.isResidentialAddress = false;
                this.businessAddress = new Address(biz);
            }

            if (mail) {
                mail.isBusinessAddress = false;
                mail.isResidentialAddress = false;
                this.mailingAddress = new Address(mail);
            }

            if (res) {
                res.isBusinessAddress = false;
                res.isMailingAddress = false;
                this.residentialAddress = new Address(res);
            }
        }
        else {
            this.businessAddress = new Address();
        }
    }
}
