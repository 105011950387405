import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '../../application.service';
import { ModalService } from '../../../../modal/modal.service';

@Component({
  selector: 'app-declined',
  templateUrl: './declined.component.html',
  styleUrls: ['./declined.component.scss']
})
export class DeclinedComponent implements OnInit {

    constructor(public appService: ApplicationService, private modal: ModalService) { }

    ngOnInit() {
        this.modal.loading(false);
    }

}
