
export function PhoneNumberFormat(value: string): string {
    if (value) {
        let newVal = value.replace(' ', '').replace(' ', '');//replace the first two spaces, leave the rest
        newVal = newVal.replace(/[()\-]/g, '');
        const len = newVal.length;
        if (len === 10 && value.endsWith(' ')) {
            newVal = newVal + ' '; //add back any final space (they're trying to add an extension)
        }

        if (len > 10) {
            const ext = newVal.slice(-(len - 10));
            newVal = newVal.substr(0, 10).replace(/\D/g, '') + ext;
        }
        else {
            newVal = newVal.replace(/\D/g, '');
        }

        if (len === 0) {
            newVal = '';
        } else if (len <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, '$1');
        } else if (len <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
        } else if (len <= 10) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
        } else {
            const ext = newVal.slice(-(len - 10));
            newVal = newVal.slice(0, 3) + '-' + newVal.slice(3, 6) + '-' + newVal.slice(6, 10);
            newVal = newVal + " " + ext;
        }
        return newVal;
    }
    return '';
}
