import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberFormat } from '../../utils/string-transforms';

@Pipe({
  name: 'appPhoneMask'
})
export class PhoneMaskDirective implements PipeTransform  {

    transform(value: string): string {
        return PhoneNumberFormat(value);
    }
}
