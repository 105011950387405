import { Component, OnInit } from '@angular/core';
import { AgentService } from '../agent.service';
import { ApplicationService } from '../../../application.service';
import { Producer } from '../../../../../shared/models/producer';
import { ModalService } from '../../../../../modal/modal.service';

@Component({
    selector: 'app-agents-table',
    templateUrl: './agents-table.component.html',
    styleUrls: ['./agents-table.component.scss']
})
export class AgentsTableComponent implements OnInit {

    constructor(public agentService: AgentService, private appService: ApplicationService, private modal: ModalService) { }

    ngOnInit() {
    }

    get hasPrimaryAgent(): Producer {
        let childs = this.agentService.childAgents;
        if (childs) {
            let primary = this.agentService.childAgents.find(x => x.isPrimaryAgent);
            if (primary) {
                return primary;
            }
        }
        return null;
    }

    setPrimaryAgent(agent: Producer) {
        this.appService.setPrimaryAgent(agent.id).subscribe(resData => {
            if (resData && resData.status == 0) {
                if (this.hasPrimaryAgent && this.hasPrimaryAgent !== agent) {
                    this.hasPrimaryAgent.isPrimaryAgent = false;
                }
                agent.isPrimaryAgent = true;
            }
            else {
                this.modal.message(resData.message);
                agent.isPrimaryAgent = false;
            }
        });
    }

    deleteAgent(agent: Producer) {
        if (confirm("Are you sure you want to remove the relationship with the producer: " + this.appService.producer.fullName + "?")) {
            this.appService.removeProducerRelationship(agent).subscribe(res => {
                if (res && res.status == 0) {
                    let agents = this.agentService.childAgents.slice();
                    let index = agents.indexOf(agent);
                    agents.splice(index, 1);
                    this.agentService.childAgents = agents;
                }
            });
        }
    }

}
