import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ApplicationService } from './application.service';
import { UserService } from '../../services/user.service';
import { ModalService } from '../../modal/modal.service';
import { Subscription } from 'rxjs';
import { Application } from '../../shared/models/application';

@Component({
    selector: 'app-application',
    templateUrl: './application.component.html',
    styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements OnInit, OnDestroy {

    appIdSub: Subscription;

    constructor(private userService: UserService, public appService: ApplicationService, private route: ActivatedRoute, private router: Router, private modal: ModalService) {
        this.appService.application = null;
        // check if a full url was input
        let parts = router.url.split('/');
        if (parts.length > 3) {
            this.appService.initRoute = parts[3];
        }

        this.appIdSub = this.appService.appId$.subscribe((id) => {
            if (id) {
                this.modal.loading(true);

                this.appService.getApplication(id).subscribe({
                    next: resData => {
                        if (resData && resData.status == 0) {
                            this.appService.setApplication(new Application(resData.item));
                        }
                        this.modal.loading(false);
                    },
                    error: err => {
                        this.modal.loading(false);
                    }
                });
            }
            else {
                this.appService.setApplication(null);
            }
        });
    }

    ngOnInit(): void {
        if (!this.userService.userLoaded) {
            this.userService.isAuthenticated$.subscribe(() => {
                this.getId();
            });//.catch(err => console.log(err));
        }
        else {
            console.log("already loaded");
            this.getId();
        }
    }

    ngOnDestroy(): void {
        this.appService.clear();
    }

    getId(): void {
        let param = this.route.snapshot.paramMap.get('id');
        const id = +param;

        if (!isNaN(id)) {
            this.appService.updateAppId(id);
        }
    }

    saveAndCloseApplication() {
        //this.appService.saveApplication();
        this.router.navigate(["/Applications", "Dashboard"]);
    }
}
