<h3>Add Agent(s)</h3>
<p>{{bizUnitMessage}}</p>

<div id="agents-container">
  <app-agents-table></app-agents-table>
  <ng-container *ngIf="!isAddingAgent">
    <a class="button inline" (click)="resetSearch()">Search for Agents</a>
  </ng-container>
  <div *ngIf="isAddingAgent">

    <div *ngIf="!hasSearched">
      <div class="agent-search-form-container">
        <h4>Search for Agent</h4>
        <form (submit)="searchFromUserInput()" *ngIf="!isSearching">
          <div>
            <mat-form-field class="full-width">
              <input name="first-name" matInput type="text" [(ngModel)]="searchInput.firstName" placeholder="First Name" />
            </mat-form-field>
            <mat-form-field class="full-width">
              <input name="last-name" matInput type="text" [(ngModel)]="searchInput.lastName" placeholder="Last Name" />
            </mat-form-field>
          </div>
          <div class="center">
            <span class="form-or">-OR-</span>
          </div>
          <div>
            <mat-form-field class="full-width">
              <input name="npn" matInput type="number" [(ngModel)]="searchInput.npn" placeholder="NPN" />
            </mat-form-field>
          </div>
          <button class="button" type="submit">Search</button>
        </form>
        <div *ngIf="isSearching">
          <div class="loading-spinner"></div>
        </div>
      </div>
    </div>

    <div *ngIf="hasSearched" style="margin-top: 20px;">
      <ng-container *ngIf="agentService.agentSearchResults">
        <h4>Select Agent</h4>
        <mat-table [dataSource]="agentService.agentSearchResults">

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let result" class="proper-name">{{result.fullName}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef>State</mat-header-cell>
            <mat-cell *matCellDef="let result">{{result.stateCode}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="npn">
            <mat-header-cell *matHeaderCellDef>NPN</mat-header-cell>
            <mat-cell *matCellDef="let result">{{result.npn}} </mat-cell>
          </ng-container>

          <mat-header-row *cdkHeaderRowDef="['name', 'state', 'npn']"></mat-header-row>
          <mat-row *cdkRowDef="let row; columns: ['name', 'state', 'npn']; index as i" class="clickable-row {{(i%2 == 0) ? 'even' : 'odd'}}-row" (click)="selectRow(row)"></mat-row>

        </mat-table>
        <a class="button inline" style="margin-top: 20px;" (click)="resetSearch()">Reset Search</a>
      </ng-container>

      <ng-container *ngIf="!agentService.agentSearchResults">
        <h4>No Results Found!</h4>
        <ng-container *ngIf="npnSearch">
          <p>A search for NPN {{searchInput.npn}} did not return any results.</p>
          <ng-container *ngIf="searchInput.firstName != '' || searchInput.lastName != ''">
            <p>To search for the name entered, omit NPN from the search</p>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="npnSearch">
          <p>A search for {{searchInput.firstName}} {{searchInput.lastName}} did not return any results.</p>
          <!--<p>If this information is correct you can add a new agent, otherwise please correct the information and try your search again.</p>-->
        </ng-container>
        <div style="margin-top: 30px;">
          <!--<a class="button" *ngIf="!npnSearch" (click)="addNewProducer()">Add Agent</a>-->
          <a class="button" style="margin-left: 40px;" (click)="resetSearch()">Reset Search</a>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<a class="button float-right page-next" (click)="completeStep()">{{appService.nextStepBtnText}}</a>
