import { Component, AfterContentInit, OnInit, Inject } from '@angular/core';
import { UserService } from './services/user.service';
import { ModalService } from './modal/modal.service';
import { Application, ShortApplication } from './shared/models/application';
import { DataService } from './services/data.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [ModalService]
})
export class AppComponent implements OnInit {

    pageTitle = 'Producer Onboarding';

    public message = "";
    queryParamSub: Subscription;

    isDocuSignRedirect: boolean = false;

    constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth, public authService: OktaAuthStateService, public userService: UserService, private dataService: DataService, private router: Router, private route: ActivatedRoute, private modal: ModalService) { }

    ngOnInit() {
        console.log('app');
        this.userService.isAuthenticated$ = this.authService.authState$.pipe(
            filter((s: AuthState) => !!s),
            map((s: AuthState) => s.isAuthenticated ?? false)
        );

        this.loadUserDataLists();
        this.queryParamSub = this.route.queryParams.subscribe((params: Params) => {
            let status: string = params.status;
            if (status) {
                this.isDocuSignRedirect = true;

                if (status == "OK") {
                    this.message = "DocuSign process complete!";
                }
                else {
                    this.message = "DocuSign had an error, it returned a status of: " + status.replace('DocusignResult', '');
                }

                let url = window.location.pathname;
                let parts = url.split('/');
                let id = parts.pop();
                localStorage.setItem("DocuSignStatus", id);
                this.queryParamSub.unsubscribe();
            }
        });
    }

    public async login(): Promise<void> {
        await this.oktaAuth.signInWithRedirect().then(
            _ => {
                this.userService.userLoaded = true;
                this.router.navigate(['Applications']);
            }
        );
    }

    public async logout(): Promise<void> {
        await this.oktaAuth.signOut();
    }

    public loadUserDataLists(): any {

        this.userService.isAuthenticated$.subscribe((status: boolean) => {
            if (status) {
                    this.dataService.getAllApplications().subscribe({
                        next: resData => {
                            if (resData && resData.status >= 0 && resData.item) {
                                this.userService.appFirstName = resData.item.firstName;
                                this.userService.appLastName = resData.item.lastName;
                                this.userService.appEmail = resData.item.email;
                                this.userService.appId = resData.item.id;
                                this.userService.applications = resData.item.applications.map(x => new ShortApplication(x));
                                this.userService.userLoaded = true;
                            }
                            else if (resData.status == -404) {
                                this.userService.userLoaded = true;
                            }
                            else if (resData.status < 0) {
                                this.userService.userLoaded = true;
                                this.message = resData.message;
                            }
                        },
                        error: err => {
                            console.log(err);
                        }
                    });
            }
        });
    }
}
